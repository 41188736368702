import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '@/routes/Dashboard/Home/pages';
import Directory from '@/routes/Dashboard/Directory/pages';

// import Login from '@/routes/Authentication/Login/pages';

import Register from '@/routes/Authentication/Register/pages';
import SetAccount from '@/routes/Authentication/Register/components/SetAccount';
import RegisterSuccessfully from '@/routes/Authentication/Register/components/CompanyUser/SuccessfullyFinished';
import EmailVerification from '@/routes/Authentication/Register/components/CompanyUser/EmailSended';
import InhouseUserRegister from '@/routes/Authentication/Register/pages/InhouseUserRegister';
import SetAccountInhouse from '@/routes/Authentication/Register/components/SetAccountInhouse';

import ForgotPassword from '@/routes/Authentication/ForgotPassword/pages';
import EnterEmail from '@/routes/Authentication/ForgotPassword/components/EnterEmail';
import EnterPhone from '@/routes/Authentication/ForgotPassword/components/EnterPhone';
import EnterOtp from '@/routes/Authentication/ForgotPassword/components/EnterOtp';
import EmailSended from '@/routes/Authentication/ForgotPassword/components/EmailSended';
import NewPassword from '@/routes/Authentication/ForgotPassword/components/NewPassword';
import SuccessfullyChanged from '@/routes/Authentication/ForgotPassword/components/SuccessfullyChanged';

import Layout from '@/routes/Dashboard/Layout';

import PersonalSetting from '@/routes/Dashboard/Setting/PersonalSetting/pages';
import CompanySetting from '@/routes/Dashboard/Setting/CompanySetting/pages';
import NotificationSetting from '@/routes/Dashboard/Setting/NotificationSetting/pages';
import SubscriptionSetting from '@/routes/Dashboard/Setting/SubscriptionSetting/pages';
import SubscriptionChangeCard from '@/routes/Dashboard/Setting/SubscriptionSetting/components/SubscriptionChangeCard';
import SubscriptionRenew from '@/routes/Dashboard/Setting/SubscriptionSetting/components/SubscriptionRenew';
import SuccessfullyChangedEmail from './Dashboard/Setting/PersonalSetting/components/SuccessfullyChangedEmail';

import Notification from '@/routes/Dashboard/Notification/pages';
import Chat from '@/routes/Dashboard/Chat/pages';

import ProjectDashboard from '@/routes/ProjectDashboard';
import Project from '@/routes/Dashboard/Project/pages';
import TenderBox from './Dashboard/TenderBox/pages';

import Summary from '@/routes/ProjectDashboard/Summary/pages';
import ProjectSetting from '@/routes/ProjectDashboard/Settings/pages';
import Schedule from '@/routes/ProjectDashboard/Schedule/pages';
import ProjectNotification from '@/routes/ProjectDashboard/Notification/pages';
import ProjectHistory from '@/routes/ProjectDashboard/History/pages';
import Drawing from '@/routes/ProjectDashboard/Drawing/pages';
import Document from '@/routes/ProjectDashboard/Document/pages';

import Help from '@/routes/Dashboard/Help/pages';

import Error404 from '@/components/organism/ErrorPage/Error404';
import Error401 from '@/components/organism/ErrorPage/Error401';
import SubscriptionSuspend from '@/components/organism/ErrorPage/SubscriptionSuspend';

import Loader from '@/components/molecules/Loader';

import { PrivateRoute, PublicRoute, StepperRoute } from '@/services/AuthService';

import RedirectAdmin from './Authentication/Login/components/RedirectAdmin';
import AccountSuspend from '@/components/organism/ErrorPage/AccountSuspend';
import CompanyUserRegister from './Authentication/Register/pages/CompanyUserRegister';
import SetAccountCompany from './Authentication/Register/components/SetAccountCompany';
import ErrorLinkExpired from '@/components/organism/ErrorPage/ErrorLinkExpired';

export default function Router(): JSX.Element {
    // Lazy Load
    const LoginLazy = lazy(
        async () => await import('@/routes/Authentication/Login/pages'),
    );

    return (
        <React.Suspense fallback={<Loader />}>
            <Routes>
                {/* No authentication routes */}

                <Route path="*" element={<Error404 />} />
                <Route path="/unauthorized" element={<Error401 />} />
                <Route path="/token-expired" element={<ErrorLinkExpired />} />

                {/* Login routes  */}
                {/* <Route index element={<Login />} /> */}
                <Route
                    path="/login"
                    element={
                        <PublicRoute title="Login">
                            <LoginLazy />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/login/administrator"
                    element={
                        <PublicRoute title="Login">
                            <RedirectAdmin />
                        </PublicRoute>
                    }
                />

                {/* Register routes */}
                <Route
                    path="/register"
                    element={
                        <PublicRoute title="Register">
                            <Register />
                        </PublicRoute>
                    }
                />
                {/* Manually register */}
                <Route
                    path="/register/verify"
                    element={
                        <StepperRoute>
                            <EmailVerification />
                        </StepperRoute>
                    }
                />

                <Route
                    path="/register/company-user" // manually register page
                    element={
                        <PublicRoute title="Set Company">
                            <SetAccount />
                        </PublicRoute>
                    }
                />

                {/* Company User from invitation */}
                <Route
                    path="/register/invitation/company-user" // invitation page company user from project
                    element={
                        <PublicRoute title="Register">
                            <CompanyUserRegister />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register/company-user/set-account" // manually register page
                    element={
                        <PublicRoute title="Set Company">
                            <SetAccountCompany />
                        </PublicRoute>
                    }
                />

                {/* Inhouse User from invitation */}
                <Route
                    path="/register/inhouse-user" // invitation page inhouse user set password
                    element={
                        <PublicRoute title="Register Inhouse">
                            <InhouseUserRegister />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register/inhouse-user/set-account" // invitation page inhouse user set company
                    element={
                        <PublicRoute title="Set Inhouse">
                            <SetAccountInhouse />
                        </PublicRoute>
                    }
                />

                <Route
                    path="/register/complete"
                    element={
                        <PublicRoute title="Register Successfully">
                            <RegisterSuccessfully />
                        </PublicRoute>
                    }
                />

                {/* Forgot password routes */}
                <Route
                    path="/forgot-password"
                    element={
                        <PublicRoute title="Forgot Password">
                            <ForgotPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/forgot-password/enter-email"
                    element={
                        <PublicRoute title="Forgot Password">
                            <EnterEmail />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/forgot-password/enter-phone"
                    element={
                        <PublicRoute title="Forgot Password">
                            <EnterPhone />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/forgot-password/enter-otp"
                    element={
                        <StepperRoute title="Forgot Password">
                            <EnterOtp />
                        </StepperRoute>
                    }
                />
                <Route
                    path="/forgot-password/email-sent"
                    element={
                        <StepperRoute title="Forgot Password">
                            <EmailSended />
                        </StepperRoute>
                    }
                />
                <Route
                    path="/reset-password/"
                    element={
                        // <PublicRoute title="Reset Password">
                        <NewPassword />
                        // </PublicRoute>
                    }
                />
                <Route
                    path="/reset-password/successfully"
                    element={
                        // <StepperRoute title="Reset Password">
                        <SuccessfullyChanged />
                        // </StepperRoute>
                    }
                />

                <Route
                    path="/email/updated"
                    element={
                        // <StepperRoute title="Reset Password">
                        <SuccessfullyChangedEmail />
                        // </StepperRoute>
                    }
                />
                {/* End no authentication routes */}

                {/* General Dashboard routes with authentication */}
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }
                >
                    <Route path="*" element={<h1>Error</h1>} />
                    <Route
                        index
                        element={
                            <PrivateRoute title="Home">
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    {/* Project Routes */}
                    <Route
                        path="/project"
                        element={
                            <PrivateRoute title="Project">
                                <Project />
                            </PrivateRoute>
                        }
                    />

                    {/* Chat Routes */}
                    <Route
                        path="/chat"
                        element={
                            <PrivateRoute title="Chat and Message">
                                <Chat />
                            </PrivateRoute>
                        }
                    />

                    {/* Directory routes */}
                    <Route
                        path="/directory"
                        element={
                            <PrivateRoute title="Directory">
                                <Directory />
                            </PrivateRoute>
                        }
                    />

                    {/* Tender Box */}
                    <Route
                        path="/tender-box"
                        element={
                            <PrivateRoute title="Tender Box" roles={['Company User']}>
                                <TenderBox />
                            </PrivateRoute>
                        }
                    />

                    {/* Help */}
                    <Route
                        path="/help"
                        element={
                            <PrivateRoute title="Help">
                                <Help />
                            </PrivateRoute>
                        }
                    />

                    {/* Setting routes */}
                    <Route
                        path="/setting/profile"
                        element={
                            <PrivateRoute title="Profile Setting">
                                <PersonalSetting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setting/company"
                        element={
                            <PrivateRoute
                                title="Company Setting"
                                roles={['Company User']}
                            >
                                <CompanySetting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setting/notification"
                        element={
                            <PrivateRoute title="Notification Setting">
                                <NotificationSetting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setting/subscription"
                        element={
                            <PrivateRoute title="Subscription Setting">
                                <SubscriptionSetting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setting/subscription/change-card"
                        element={
                            <PrivateRoute title="Change Card">
                                <SubscriptionChangeCard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setting/subscription/renew"
                        element={
                            <PrivateRoute title="Renew Subscription">
                                <SubscriptionRenew />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/notification"
                        element={
                            <PrivateRoute title="Notifications">
                                <Notification />
                            </PrivateRoute>
                        }
                    />

                    {/* Inactive account because not recharge subscription */}
                    <Route
                        path="/account-suspend"
                        element={
                            <PrivateRoute title="Account Suspend">
                                <SubscriptionSuspend />
                            </PrivateRoute>
                        }
                    />

                    {/* If Account was terminated by admin */}
                    <Route
                        path="/account-terminated"
                        element={
                            <PrivateRoute title="Account Suspend">
                                <AccountSuspend />
                            </PrivateRoute>
                        }
                    />

                    {/* Project dashboard routes */}
                    <Route path="/project/:id/" element={<ProjectDashboard />}>
                        <Route
                            path="summary"
                            element={
                                <PrivateRoute title="Summary">
                                    <Summary />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="setup"
                            element={
                                <PrivateRoute title="Project Setting">
                                    <ProjectSetting />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="schedule"
                            element={
                                <PrivateRoute title="Schedule">
                                    <Schedule />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="drawings/:urlTypeId/:urlSpaceId?/:urlFolderId?"
                            element={
                                <PrivateRoute title="Drawings">
                                    <Drawing />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="documents/:urlTypeId/:urlSpaceId?/:urlFolderId?"
                            element={
                                <PrivateRoute title="Documents">
                                    <Document />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="notification"
                            element={
                                <PrivateRoute title="Project Notification">
                                    <ProjectNotification />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="history"
                            element={
                                <PrivateRoute title="Project History">
                                    <ProjectHistory />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    {/* End project dashboard */}

                    {/* End dashboard routes */}
                </Route>
            </Routes>
        </React.Suspense>
    );
}
