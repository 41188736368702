import styled from 'styled-components';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { FaStarOfLife } from 'react-icons/fa';
import { useEffect, useState } from 'react';

export default function InputDateRangeHeaderNew(props: {
    id?: string;
    outerClassName?: string;
    innerClassName?: string;
    value: [Date | null, Date | null];
    onChange: (date: [Date | null, Date | null]) => void;
    required?: boolean;
    isClearable?: boolean;
    maxDate?: Date | null;
    minDate?: Date | null;
    disabled?: boolean;
}): JSX.Element {
    const {
        id,
        outerClassName,
        innerClassName,
        value,
        onChange,
        required,
        isClearable,
        maxDate,
        minDate,
        disabled,
    } = props;

    const [startDate, endDate] = value;
    const [dates, setDates] = useState<[Date | null, Date | null]>([null, null]);

    const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
    const [isOpenMonthYearPicker, setIsOpenMonthYearPicker] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    useEffect(() => {
        setDates([startDate, endDate]);
        // void axios.get('https://dayoffapi.vercel.app/api').then(res => {
        //     console.log(res.data);
        // });

        if ((required ?? false) && value[0] === null && value[1] === null) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [value]);

    const onDateChange = (date: [Date | null, Date | null]): void => {
        setDates(date);
        onChange(date);
    };

    return (
        <InputWrapper className={`${outerClassName as string}`} disabled={disabled}>
            <DatePicker
                id={id}
                className="form-control"
                selectsRange={true}
                startDate={dates[0]}
                endDate={dates[1]}
                onChange={onDateChange}
                open={isOpenCalendar}
                onInputClick={() => setIsOpenCalendar(true)}
                onClickOutside={() => setIsOpenCalendar(false)}
                isClearable={isClearable}
                required={required}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                dateFormat="dd/MM/yyyy"
                shouldCloseOnSelect={false}
                renderMonthContent={(
                    monthIndex: number,
                    shortMonthText: string,
                    fullMonthText: string,
                ) => {
                    return <span>{shortMonthText}</span>;
                }}
                showMonthYearPicker={isOpenMonthYearPicker}
                formatWeekDay={(day: string) => {
                    return day.substring(0, 1);
                }}
                renderCustomHeader={({
                    monthDate,
                    date,
                    changeYear,
                    changeMonth,
                    customHeaderCount,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    decreaseYear,
                    increaseYear,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled,
                }) => {
                    return (
                        <div className="d-flex flex-column">
                            <div
                                className="d-flex align-items-center justify-content-between w-100"
                                style={{
                                    padding: 16,
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                        setIsOpenCalendar(false);
                                        onChange([null, null]);
                                        setDates([null, null]);
                                    }}
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                        fill="white"
                                    />
                                </svg>
                                <span
                                    style={{
                                        fontSize: '14px',
                                    }}
                                    role="button"
                                    onClick={() => setIsOpenCalendar(false)}
                                >
                                    SAVE
                                </span>
                            </div>
                            <div
                                className="d-flex flex-column align-items-start"
                                style={{
                                    padding: '8px 40px 24px 40px',
                                }}
                            >
                                <span style={{ fontSize: 10 }}>SELECTED RANGE</span>

                                <div className="d-flex justify-content-between align-items-center gap-5">
                                    <span style={{ fontSize: 24 }}>{`${
                                        startDate !== null
                                            ? moment(startDate).format(
                                                  isOpenMonthYearPicker
                                                      ? 'MMM YYYY'
                                                      : 'MMM DD',
                                              )
                                            : moment(date).format(
                                                  isOpenMonthYearPicker
                                                      ? 'MMM YYYY'
                                                      : 'MMM DD',
                                              )
                                    } - ${
                                        endDate !== null
                                            ? moment(endDate).format(
                                                  isOpenMonthYearPicker
                                                      ? 'MMM YYYY'
                                                      : 'MMM DD',
                                              )
                                            : moment(date).format(
                                                  isOpenMonthYearPicker
                                                      ? 'MMM YYYY'
                                                      : 'MMM DD',
                                              )
                                    }`}</span>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() =>
                                            setIsOpenMonthYearPicker(
                                                !isOpenMonthYearPicker,
                                            )
                                        }
                                        role="button"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                {/* {moment(date).format('MMMM YYYY')} */}

                                <div className="d-flex align-items-center gap-1">
                                    {!isOpenMonthYearPicker && (
                                        <select
                                            value={moment(date).format('MMMM')}
                                            onChange={e => {
                                                changeMonth(
                                                    months.indexOf(e.target.value),
                                                );
                                            }}
                                        >
                                            {months.map(month => (
                                                <option key={month} value={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    )}

                                    <select
                                        value={moment(date).format('YYYY')}
                                        onChange={e => {
                                            changeYear(parseInt(e.target.value));
                                        }}
                                    >
                                        {Array.from(Array(21).keys()).map((_, index) => {
                                            const year = moment(date).year() - 10 + index;
                                            return (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    );
                }}
                customInput={
                    <div
                        className={`date-range d-flex justify-content-between align-items-center${
                            innerClassName as string
                        }`}
                    >
                        <span className="">
                            {startDate != null && endDate != null ? (
                                // `${.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                `${moment(startDate).format('DD MMM YYYY')} - ${moment(
                                    endDate,
                                ).format('DD MMM YYYY')}`
                            ) : (
                                //  {moment(startDate).format('DD MMM YYYY')} -{' '}
                                //     {moment(endDate).format('DD MMM YYYY')}
                                <p className="mb-0">
                                    Date{' '}
                                    {required === true && (
                                        <FaStarOfLife
                                            color="#FB6056"
                                            size={7}
                                            style={{
                                                position: 'relative',
                                                top: -6,
                                                left: -2,
                                            }}
                                        />
                                    )}
                                </p>
                            )}
                        </span>
                        <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.625 7.875H18.375M6.125 2.625V4.375M14.875 2.625V4.375M5.25 11.375H7M5.25 14.875H7M9.625 11.375H11.375M9.625 14.875H11.375M14 11.375H15.75M14 14.875H15.75M5.425 18.375H15.575C16.5551 18.375 17.0451 18.375 17.4195 18.1843C17.7488 18.0165 18.0165 17.7488 18.1843 17.4195C18.375 17.0451 18.375 16.5551 18.375 15.575V7.175C18.375 6.19491 18.375 5.70486 18.1843 5.33052C18.0165 5.00123 17.7488 4.73352 17.4195 4.56574C17.0451 4.375 16.5551 4.375 15.575 4.375H5.425C4.44491 4.375 3.95486 4.375 3.58052 4.56574C3.25123 4.73352 2.98352 5.00123 2.81574 5.33052C2.625 5.70486 2.625 6.19491 2.625 7.175V15.575C2.625 16.5551 2.625 17.0451 2.81574 17.4195C2.98352 17.7488 3.25123 18.0165 3.58052 18.1843C3.95486 18.375 4.44491 18.375 5.425 18.375Z"
                                stroke="#5648FB"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                }
            />
            {isError && (
                <span
                    className="invalid-feedback d-block text-start"
                    style={{
                        fontSize: '0.75rem',
                    }}
                >
                    Date is required
                </span>
            )}
        </InputWrapper>
    );
}

const InputWrapper = styled.div<{ disabled?: boolean }>`
    cursor: ${props => (props.disabled ?? false ? 'not-allowed' : 'pointer')};

    select {
        background-color: transparent;
        border: none;
        color: #fff;
        padding: 0;
        box-shadow: none;
        /* -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none; */
    }

    .form-control {
        font-size: 14px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 5px !important;
        padding-left: 0 !important;
    }

    .react-datepicker-ignore-onclickoutside {
        box-shadow: none;
        border-color: #5648fb;
    }

    .date-range {
        padding: 17px 0 !important;
    }

    .react-datepicker__close-icon::after {
        background-color: #5648fb;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
        border-radius: 50% !important;
        background-color: #5648fb !important;
        color: #fff !important;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
        background-color: #5648fb !important;
        color: #fff !important;
        border-radius: 50% !important;
    }

    .react-datepicker__day--selecting-range-end .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: #f2e7fe;
        color: #000;
    }

    .react-datepicker__day--in-range {
        background-color: #f2e7fe;
        color: #000;
    }

    .react-datepicker {
        border-radius: 0px;
        border: 1px solid #e6e6e6;
    }

    .react-datepicker__header {
        background-color: #453ac9 !important;
        border: none !important;
        border-radius: 0;
        color: white;
        padding: 0px;
        cursor: default;
    }

    .react-datepicker__day-names {
        background-color: #fff;
    }

    .react-datepicker__day-name {
        margin: 8px;
    }

    .react-datepicker__day {
        margin: 8px;
    }

    .react-datepicker__month-text {
        margin: 8px;
        text-align: center;
    }

    .react-datepicker__month-text--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker__month-text--in-selecting-range {
        background-color: #f2e7fe;
        color: #000;
    }

    .react-datepicker__month-text--selecting-range-start,
    .react-datepicker__month-text--selecting-range-end {
        background-color: #5648fb;
        color: #ffffff;
    }

    .react-datepicker__month-text
        .react-datepicker__month-text--in-range
        .react-datepicker__month-text--range-start {
        background-color: #5648fb;
        color: #ffffff;
    }

    .react-datepicker__month-text--in-range {
        background-color: #f2e7fe;
        color: #000;
    }

    .react-datepicker__month-text--range-start,
    .react-datepicker__month-text--range-end {
        background-color: #5648fb;
        color: #ffffff;
    }
`;
